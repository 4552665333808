'use client'
import { type StorefrontWidget, type WidgetRelatedProducts } from 'ecosystem'
import { useEffect } from 'react'
import RelatedProductsWidgetBase from 'storefront-modules/products/widgets/RelatedProductsWidgetBase'
import type { OverridesContextType } from 'ui/lib/overrides'
import { useGetProductsMutation } from 'shared-redux/state'
import useProductRequest from '#/src/custom/products/useProductRequest'
import { useCustomCart } from '#/src/custom/cart/useCustomCart'
import appConfig from '#/src/appConfig'

interface RelatedProductsWidgetProps {
  widget: StorefrontWidget<WidgetRelatedProducts>
  overrides?: OverridesContextType
}

export const CustomRelatedProductsWidget = ({ widget }: RelatedProductsWidgetProps) => {
  const [fetchProducts, { data, isLoading }] = useGetProductsMutation()
  const { handleProductRequest } = useProductRequest()
  const { handleAddToCart } = useCustomCart()

  useEffect(() => {
    const relatedProductIds = widget.widget.relatedProducts.map((rp) => rp.id)
    void fetchProducts({ filter: { productIds: relatedProductIds } })
  }, [fetchProducts, widget.widget.relatedProducts])

  return (
    <RelatedProductsWidgetBase
      key={widget.widget.name}
      config={{
        title: widget.widget.title,
        relatedProducts: data?.content || [],
        onProductRequest: handleProductRequest,
        deliveryOptions: appConfig.storeDeliveries,
        onProductBuy: handleAddToCart,
        paths: {
          productsUrlPath: appConfig.paths.productsUrlPath
        },
        itemCount: data?.content?.length || 0,
        isLoading
      }}
    />
  )
}
